import Head from "next/head";
import Image from "next/image";
import { Fragment } from "react";
import styles from "../styles/Home.module.css";
import Levels from "./levels";
import logo from "../assets/sidebar-logo.svg";
import { useMediaQuery } from "@mui/material";

export default function Home() {
  const smDown = useMediaQuery("(max-width:600px)");
  return (
    <div>
      <Head>
        <title>Gamification portal</title>
        <meta name="description" content="Leetbit gamification portal" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main>
        {
          <Fragment>
            <Levels />
          </Fragment>
        }
      </main>
      {/* <footer className={styles.footer}>
        <a></a>
      </footer> */}
    </div>
  );
}
